<template>
  <v-container fluid>
    <!-- Create Button -->
    <v-btn
      class="mr-2"
      fab
      dark
      color="blue"
      :absolute="true"
      :right="true"
      v-if="!isViewer"
      @click="_add"
    >
      <v-icon dark>mdi-plus</v-icon>
    </v-btn>
    <!-- List Table -->
    <v-card>
      <v-card-title class="mr-10">
        <v-text-field
          v-model="search"
          append-icon="mdi-search"
          label="Search"
          single-line
          hide-details
          @change="gets"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :footer-props="{'items-per-page-options': $itemsPerPage}"
        :items-per-page="perpage"
        class="elevation-1"
        :loading="loading"
        :options.sync="options"
        :server-items-length="countItems"
        loading-text="Loading... Please wait"
      >
        <template v-slot:[`item.role`]="{ item }">
          <div
            :class="(item.role === $rolesMember[1].value)? '': 'primary--text'"
            :title="(!item.confirm)? 'Resend E-mail.': ''"
          >
            <span class="mr-1">{{item.role}}</span>
            <v-icon color="warning" v-if="!item.confirm" @click="_resend(item)">mdi-alert</v-icon>
          </div>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-icon
            title="edit"
            @click="_edit(item)"
            :disabled="isViewer"
            class="mr-1"
          >mdi-circle-edit-outline</v-icon>
          <v-icon
            title="delete"
            @click="_delete(item)"
            :disabled="isViewer"
            class="mr-1"
          >mdi-delete-circle-outline</v-icon>
        </template>
      </v-data-table>
    </v-card>
    <!-- Form Dialog -->
    <v-dialog v-model="dialog" persistent max-width="1000px">
      <v-form v-model="valid" :lazy-validation="false" @submit.prevent="_save">
        <v-card>
          <v-card-title>
            <span class="headline">Member</span>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="9">
                  <v-combobox
                    v-model="form.email"
                    :items="emails"
                    :search-input.sync="searchEmail"
                    :rules="rules.email"
                    :disabled="form && form.id && true"
                    @change="_selectEmail"
                    label="Email"
                    hide-selected
                    multiple
                    chips
                    return-object
                    deletable-chips
                  >
                    <template v-slot:selection="{ attrs, item, parent, selected }">
                      <v-chip v-if="item === item" v-bind="attrs" :input-value="selected" label>
                        <span class="pr-2">{{ item }}</span>
                        <v-icon small @click="parent.selectItem(item)">mdi-close</v-icon>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-select v-model="form.role" :items="roles" :rules="rules.role" label="Role" height="42"></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error darken-1" text @click="dialog = false">Close</v-btn>
            <v-btn
              color="green darken-1"
              text
              type="submit"
              :disabled="!valid"
            >{{form && form.id && 'Edit' || 'Add'}} member</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- Confirm -->
    <v-dialog v-model="confirm" max-width="400">
      <v-card>
        <v-card-title
          class="headline"
        >{{(item && item.action === "resend")? "Do you want to resend E-mail?": "Do you want to delete member?"}}</v-card-title>

        <v-card-text>{{item && item.email}}</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error darken-1" text @click="confirm = false">Cancel</v-btn>

          <v-btn color="green darken-1" text @click="_confirm">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Member from "@/store/models/Member";
import User from "@/store/models/User";

export default {
  components: {},
  data: function () {
    return {
      dialog: false,
      valid: false,
      form: {
        email: [],
        role: this.$rolesMember[1] && this.$rolesMember[1].value || "viewer",
      },
      item: null,
      items: [],
      search: "",
      options: {},
      perpage: 10,
      countItems: 0,
      loading: false,
      select: [],
      emails: [],
      searchEmail: "",
      rules: {
        email: [
          (v) => v.length > 0 || "E-mail is required",
          (v) =>
            !v.find((e) => !this.$regexEmail.test(e)) || "E-mail must be valid",
        ],
        role: [
          (v) => !!v || "Role is required",
        ],
      },
      searchTimeout: null,
      confirm: false,
    };
  },
  created() {
    this.gets();
  },
  watch: {
    options: {
      handler() {
        this.gets();
      },
      deep: false,
    },
    projId: {
      handler() {
        this.gets();
      },
      deep: false,
    },
    searchEmail: {
      handler() {
        this.searchTimeout && clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          this.getEmails();
        }, 800);
      },
      deep: false,
    },
    "form.email": {
      handler(v) {
        const len = v.length;

        // Check email
        if (len > 0) {
          const text = v[len - 1];
          if (!this.$regexEmail.test(text)) {
            v.pop();
            this.searchEmail = text + " ";
          }
        }
      },
      deep: false,
    },
  },
  computed: {
    projId() {
      return this.$store.$db().model("projId").find(1);
    },
    isViewer() {
      const projId = this.projId
      return projId && projId.access && projId.access.role === this.$rolesMember[1].value
    },
    projRole() {
      const projId = this.$store.$db().model("projId").find(1);
      return projId && projId.access && projId.access.role;
    },
    headers() {
      return [
        {
          text: "E-mail",
          value: "email",
          sortable: false,
          align: "left",
        },
        {
          text: "Role",
          value: "role",
          sortable: false,
          align: "left",
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
          align: "center",
        },
      ];
    },
    membersEmail() {
      return this.items.map((e) => e.email);
    },
    roles() {
      return this.$rolesMember;
    },
  },
  methods: {
    gets() {
      this.loading = true;

      let params = {};

      // Search
      if (this.search) {
        params.query = this.search;
      }

      // Per page
      if (this.options && this.options.itemsPerPage) {
        params.perpage = this.options.itemsPerPage;
      }

      // Page
      if (this.options && this.options.page) {
        params.page = this.options.page;
      }

      params.sort = {
        created_at: -1,
        updated_at: -1,
        name: 1,
      };

      if (params.page && params.perpage && this.projId && this.projId.value) {
        params.condition = {};
        const options = { params };

        Member.gets(this.projId.value, options)
          .then((res) => {
            this.items = res.data || [];
            this.countItems = res.count || 0;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            console.error("Get projects err: ", err);
          });
      }
    },
    getEmails() {
      if (this.searchEmail && this.searchEmail.length > 3) {
        const options = {
          params: {
            page: 1,
            perpage: 10,
            query: this.searchEmail,
            condition: {
              email: {
                $nin: this.membersEmail,
              },
            },
          },
        };

        User.emails(options)
          .then((res) => {
            this.emails = res;
            return res;
          })
          .catch((err) => {
            console.error("Get emails err: ", err);
          });
      }
    },
    _selectEmail() {
      this.searchEmail = "";
    },
    _add() {
      (this.form = { email: [], role: this.$rolesMember[1] && this.$rolesMember[1].value || "viewer" }), (this.dialog = true);
    },
    _edit(item) {
      this.form = {
        id: item.id,
        email: [item.email],
        role: item.role,
      };
      this.dialog = true;
    },
    _confirm() {
      if (this.item && this.item.action === "delete") {
        return this.delete();
      }
      return this.resend();
    },
    _delete(item) {
      this.item = {
        ...item,
        action: "delete",
      };
      this.confirm = true;
    },
    delete() {
      this.loading = true;

      Member.api().delete(`/cms/members/${this.item && this.item.id}`, {
          delete: this.item && this.item.id,
        })
        .then((res) => {
          this.confirm = false;
          this.gets();
          this.item = null;

          return res;
        })
        .catch((err) => {
          console.error("Delete member: ", err);
          this.confirm = false;
          this.loading = false;
          return err;
        });
    },
    _save() {
      if (!this.valid) {
        return;
      }

      if (this.form && this.form.id) {
        this.update();
      } else {
        this.add();
      }
    },
    add() {
      this.loading = true;
      const options = {
        data: this.form,
      };

      Member.invite(this.projId && this.projId.value, options)
        .then((res) => {
          this.form = {
            email: [],
            role: this.$rolesMember[1].value,
          };
          this.dialog = false;
          this.gets();

          return res;
        })
        .catch((err) => {
          console.error("Add member: ", err);
          this.loading = false;
          return err;
        });
    },
    update() {
      this.loading = true;
      const options = {
        data: {
          role: this.form && this.form.role,
        },
      };

      Member.update(this.form.id, options)
        .then((res) => {
          this.form = {
            email: [],
            role: this.$rolesMember[1].value,
          };
          this.dialog = false;
          this.gets();

          return res;
        })
        .catch((err) => {
          console.error("Add member: ", err);
          this.loading = false;
          return err;
        });
    },
    _resend(item) {
      if (this.isViewer) {
        return;
      }

      this.item = {
        ...item,
        action: "resend",
      };
      this.confirm = true;
    },
    resend() {
      this.loading = true;
      const options = {
        data: {
          email: [this.item && this.item.email],
          role: this.item && this.item.role,
        },
      };

      Member.invite(this.projId && this.projId.value, options)
        .then((res) => {
          this.confirm = false;
          this.item = null;
          this.loading = false;

          return res;
        })
        .catch((err) => {
          console.error("Resend email: ", err);
          this.confirm = false;
          this.loading = false;
          return err;
        });
    },
  },
};
</script>